<template>
  <div class="box-items">
    <div class="box-item">
      <img src="@/assets/image/homepage/background.png" alt="">
      <div class="box-body">
        <div class="item1">
          <img style="width: 392px" src="@/assets/image/homepage/homepage_logo.png">
          <div  v-for="(item,index) in userInfo" :key="index" >
            <div>{{ item. label }}</div>
            <div>
              <img :src=item.icon alt="">
              <countTo :startVal='item.startVal' :endVal='item.endVal' :duration='1800'></countTo>
              <span>+</span>
            </div>
          </div>
        </div>
        <div class="division"/>
        <div class="item2">
          <img src="@/assets/image/homepage/logo.png" alt="">
          <div class="beef-txt"><span>About</span><span>BTC</span></div>
          <div>Beef to China</div>
          <div>Welcome to Beef to China, your best explorer in the Chinese Beef Market!</div>
        </div>
        <div class="item3">
          <div>
            <p style="text-align: justify">Beef to China (‘BTC’) was founded in 2014. As a third-party platform, BTC specializes in providing beef market information, beef industrial data, spot&futures beef price index and third-party beef transaction services, relying on the support and operations by our headquarter in Chengdu, China.</p>
            <p style="text-align: justify">For better serving our memberships to explore unfamiliar Chinese beef buyers, valuable market information and potential business opportunities, BTC generates commercial links and industry communities between global beef exporters and the whole chain of the Chinese beef industry which includes beef importers, beef processing facilities, beef wholesalers and restaurants or supermarkets.</p>
          </div>
          <div>
            <p style="text-align: justify">Aiming at creating a secure, effective and neutral platform for beef buyers from China and global beef sources approved by the General Administration of Customs, P.R.China (GACC), BTC has successfully served a coverage rate of 90% of Chinese importers from China, and a coverage rate of 80% of beef establishments from the entire 30+ beef exporting countries admitted by the GACC, including Brazil, Argentina, Uruguay, Australia, New Zealand, etc..</p>
            <p style="text-align: justify">Meanwhile, BTC users range up to official departments or agencies of the exporting countries, which include industrial associations, embassies, consulates, agricultural departments, provincial governments, as well as research-related institutions and organizations.</p>
          </div>
        </div>
      </div>
    </div>
    <img style="width: 1200px;height: 4650px;" class="img-body" src="@/assets/image/homepage/body.png" alt="">
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  name: 'HomePage',
  components: { countTo },
  data () {
    return {
      userInfo: [
        { label: 'Total of Countries', icon: require('@/assets/image/homepage/world.png'), startVal: 0, endVal: 30 },
        { label: 'Chinese Memberships', icon: require('@/assets/image/homepage/china.png'), startVal: 0, endVal: 20000 },
        { label: 'Overseas Memberships', icon: require('@/assets/image/homepage/global.png'), startVal: 0, endVal: 5000 }
      ]
    }
  }
}
</script>

<style scoped>

@media screen and (width: 1366px) {
  .img-body {
    height: 3307px;
    width: 1366px;
  }
  .box-item {
    width: 1366px;
    position: relative;
  }
}

@media screen and (min-width: 1920px) {
  .img-body {
    height: 4648px;
    width: 1920px;
  }
  .box-item {
    width: 1920px;
    position: relative;
  }
}

@media screen and (min-width: 2048px) {
  .img-body {
    height: 6300px;
    width: 2560px;
  }
  .box-item {
    width: 2560px;
    position: relative;
  }
}

.box-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-item {
  position: relative;
}
.box-item > img{
  width: 100%;
  height: 672px;
}

.box-item > div{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.box-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
}

.item1{
  width: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px 0;
}

.item1 > div{
  padding: 0 100px 0 20px;
  border-left: 2px solid #797979;
}

.item1 > div:last-child{
  border-right: 2px solid #797979;
}

.item1 > div > div:first-child{
  font-size: 14px;
  margin-bottom: 5px;
}
.item1  > div > div:last-child{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.item1  > div > div:last-child > img{
  width: 36px;
  height: 36px;
  margin-right: 50px;
}

.division{
  width: 100%;
  border-bottom: 2px solid #767676;
}

.item2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  margin-top: 5px;
}
.item2 > img:first-child{
  width: 115px;
  height: 112px;
  margin-bottom: -120px;
  z-index: 3;
}

.item2 > div{
  font-size: 20px;
  margin-top: 20px;
}
.item2 > div:last-child{
  font-size: 14px;
}

.item3{
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.item3 > div{
  width: 525px;
  text-align: left;
}

.beef-txt {
  font-size: 100px !important;
  font-family: Source Han Sans CN !important;
  font-weight: 800;
  line-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 623px;
  color: #F6F6F7;
  opacity: 0.05;
  margin-right: 112px;
}
</style>
